/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/* For use in src/lib/core/theming/_palette.scss */
$mat-myapp-primary: (
  50 : #e9f2fb,
  100 : #c8def6,
  200 : #a4c8f0,
  300 : #80b1ea,
  400 : #64a1e6,
  500 : #4990e1,
  600 : #4288dd,
  700 : #397dd9,
  800 : #3173d5,
  900 : #2161cd,
  A100 : #ffffff,
  A200 : #d3e2ff,
  A400 : #a0c0ff,
  A700 : #86b0ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$UnidoProject-primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-myapp-primary);
// $UnidoProject-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$UnidoProject-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$UnidoProject-theme: mat-light-theme($UnidoProject-primary, $accent, $UnidoProject-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($UnidoProject-theme);

/* You can add global styles to this file, and also import other style files */
@import '~ngx-toastr/toastr';



/* Importing Bootstrap SCSS file. */
//@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
//@import "~ngx-bootstrap/datepicker/bs-datepicker";